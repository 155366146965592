import qs from "qs";
import base from "./base";
import axios from "./axios";

const common = {
  // 公众号绑定用户
  bindUser(data) {
    return axios({
      url: base.api + "/login/enterpriseWeixin",
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  // 上传文件
  uploadFileApi(data) {
    return axios.post(base.api + "/sys/file/mongdbUploadFile", data);
  },
  // 上传文件
  uploadMinioFileApi(data) {
    return axios.post(base.api + "/sys/file/minio/uploadMinioType", data);
  },
  // 删除minio文件
  getDeleteFile(data) {
    return axios.get(base.api + "/sys/file/minio/del/" + data.id);
  },
  // JS-SDK配置
  getSdkConfig(data) {
    return axios.get(base.api + "/weChatPage/jsSdkConfig", {
      params: data,
    });
  },
  // 申请停运报告附件上传
  uploadFileRecoverApi(data) {
    return axios.post(base.api + "/oss/minio/upload/recover", data);
  },
  // 通过uuid获取文件
  getFileByUuid(data) {
    return axios.get(`${base.api}/oss/minio/getFileByUuid`, {
      params: data,
    });
  },
  // 通过uuid进行文件上传
  postUploadUuid(data) {
    return axios.post(base.api + "/oss/minio/upload/uuid", data);
  },
  // 获取唯一uuid
  getUuid() {
    return axios.get(base.api + "/oss/minio/getUuid");
  },
};

export default common;
