import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keepAliveList: [], // 页面缓存集合
  },
  mutations: {
    // 设置页面缓存集合
    getKeepAliveList(state, data) {
      state.keepAliveList = data;
    },
  },
  actions: {
    createKeepAliveList({ commit }, data) {
      commit("getKeepAliveList", data);
    },
  },
  getters: {
    keepAliveList: (state) => state.keepAliveList,
  },
  modules: {},
});
