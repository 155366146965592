import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const userType = localStorage.getItem("userType");
const roleId = localStorage.getItem("roleId");
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: userType == 1 ? (roleId == 2 ? "/boss" : "/index") : "/event",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    children: [
      {
        // 首页
        path: "index",
        name: "Index",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/index/index.vue"),
      },
      {
        // 事件
        path: "event",
        name: "Event",
        component: () =>
          import(/* webpackChunkName: "event" */ "../views/event/event.vue"),
      },
      {
        // 企业事件处理
        path: "enterprise-process",
        name: "EnterpriseProcess",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/enterprise-process.vue"
          ),
      },
      {
        // 执法部门事件处理
        path: "enforcement-process",
        name: "EnforcementProcess",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/enforcement-process.vue"
          ),
      },
      {
        // 事件查看
        path: "event-read",
        name: "EventRead",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/event-read.vue"
          ),
      },
      {
        // 企业
        path: "enterprise",
        name: "Enterprise",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise.vue"
          ),
      },
      {
        // 企业详情
        path: "enterprise-detail",
        name: "EnterpriseDetail",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-detail.vue"
          ),
      },
      {
        // 停运申请
        path: "enterprise-stop",
        name: "EnterpriseStop",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-stop.vue"
          ),
      },
      {
        // 停运记录
        path: "enterprise-stop-examine",
        name: "EnterpriseStopExamine",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-stop-examine.vue"
          ),
      },
      {
        // 停运记录
        path: "enterprise-stop-log",
        name: "EnterpriseStopLog",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-stop-log.vue"
          ),
      },
      {
        // 停运恢复申请
        path: "enterprise-stop-application",
        name: "EnterpriseStopApplication",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-stop-application.vue"
          ),
      },
      {
        // 停运恢复申请记录
        path: "enterprise-application-log",
        name: "EnterpriseApplicationLog",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-application-log.vue"
          ),
      },
      {
        // 停运恢复申请记录
        path: "enterprise-shutdown-record",
        name: "EnterpriseShutdownRecord",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-shutdown-record.vue"
          ),
      },
      {
        // 停运恢复审核申请
        path: "enterprise-outage-audit",
        name: "EnterpriseOutageAudit",
        component: () =>
          import(
            /* webpackChunkName: "enterprise" */ "../views/enterprise/enterprise-outage-audit.vue"
          ),
      },

      {
        // 运维
        path: "operation",
        name: "Operation",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/operation/operation.vue"
          ),
      },
      {
        // 运维详情
        path: "operation-detail",
        name: "OperationDetail",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/operation/operation-detail.vue"
          ),
      },
      {
        // 表格-选择运维状态
        path: "table-operation-select",
        name: "TableOperationSelect",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-operation-select.vue"
          ),
      },
      {
        // 表格
        path: "table",
        name: "Table",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table.vue"
          ),
      },
      {
        // (水质)-对比试验结果记录表
        path: "table-dbsyjgjlb",
        name: "Tabledbsyjgjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-dbsyjgjlb.vue"
          ),
      },
      {
        // (水质)-废液收集记录表
        path: "table-fysjjlb",
        name: "Tablefysjjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-fysjjlb.vue"
          ),
      },
      {
        // (水质)-废液收集记录表
        path: "table-gzwxjlb",
        name: "Tablegzwxjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-gzwxjlb.vue"
          ),
      },
      {
        // (水质)-试剂更换记录表
        path: "table-sjghjlb",
        name: "Tablesjghjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-sjghjlb.vue"
          ),
      },
      {
        // (水质)-标液核查及校准记录表
        path: "table-byhcjjzjlb",
        name: "Tablebyhcjjzjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-byhcjjzjlb.vue"
          ),
      },
      {
        // (水质)-运营维护日常巡检表
        path: "table-yywhrcxjb",
        name: "Tableyywhrcxjb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-yywhrcxjb.vue"
          ),
      },
      {
        // (烟气)-烟气自动监测设备日常巡检维护记录表
        path: "table-rcxjwhjlb",
        name: "Tablercxjwhjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-rcxjwhjlb.vue"
          ),
      },
      {
        // (烟气)-烟气自动监测系统零漂、量漂校准记录表
        path: "table-lplpxzjlb",
        name: "Tablelplpxzjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-lplpxzjlb.vue"
          ),
      },
      {
        // (烟气)烟气自动监测系统--易耗品更换记录表
        path: "table-yhpghjlb",
        name: "Tableyhpghjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-yhpghjlb.vue"
          ),
      },
      {
        // (烟气)烟气自动监测系统--标准气体更换记录表
        path: "table-bzqtghjlb",
        name: "Tableybzqtghjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-bzqtghjlb.vue"
          ),
      },
      {
        // (烟气)烟气自动监测系统--CEMS维修记录表
        path: "table-wxjlb",
        name: "Tableywxjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-wxjlb.vue"
          ),
      },
      {
        // (烟气)烟气自动监测系统--CEMS校验测试记录表
        path: "table-jycsjlb",
        name: "Tablejycsjlb",
        component: () =>
          import(
            /* webpackChunkName: "operation" */ "../views/table/table-jycsjlb.vue"
          ),
      },
      // 电子档案
      {
        path: "Setting",
        name: "Setting",
        component: () => import("../views/setting/index.vue"),
      },
      // 基本信息
      {
        path: "setting-detail",
        name: "SettingDetail",
        component: () => import("../views/setting/detail.vue"),
      },
      // 排污许可
      {
        path: "setting-blowoff",
        name: "SettingBlowoff",
        component: () => import("../views/setting/blowoff.vue"),
      },
      // 排污许可新增&修改
      {
        path: "setting-blowoff-edit",
        name: "SettingBlowoffEdit",
        component: () => import("../views/setting/blowoff-edit.vue"),
      },
      // 应急预案
      {
        path: "setting-emergency",
        name: "SettingEmergency",
        component: () => import("../views/setting/emergency.vue"),
      },
      // 排污许可新增&修改
      {
        path: "setting-emergency-edit",
        name: "SettingEmergencyEdit",
        component: () => import("../views/setting/emergency-edit.vue"),
      },
      // 环评信息
      {
        path: "setting-eiainfo",
        name: "SettingEiainfo",
        component: () => import("../views/setting/eiainfo.vue"),
      },
      // 排污许可新增&修改
      {
        path: "setting-eiainfo-edit",
        name: "SettingEiainfoEdit",
        component: () => import("../views/setting/eiainfo-edit.vue"),
      },
      // 监测验收
      {
        path: "setting-monitorinspected",
        name: "SettingMonitorinspected",
        component: () => import("../views/setting/monitorinspected.vue"),
      },
      // 监测验收新增&修改
      {
        path: "setting-monitorinspected-edit",
        name: "SettingMonitorinspectedEdit",
        component: () => import("../views/setting/monitorinspected-edit.vue"),
      },
      // 运维企业申报
      {
        path: "setting-operation",
        name: "settingOperation",
        component: () => import("../views/setting/operation.vue"),
      },
      // 运维企业申报新增
      {
        path: "setting-operation-edit",
        name: "settingOperationEdit",
        component: () => import("../views/setting/operation-edit.vue"),
      },
      // 一企一档申报
      {
        path: "setting-enterprise",
        name: "settingEnterprise",
        component: () => import("../views/setting/enterprise.vue"),
      },
      // 一企一档申报新增
      {
        path: "setting-enterprise-edit",
        name: "settingEnterpriseEdit",
        component: () => import("../views/setting/enterprise-edit.vue"),
      },
      // 站点信息申报
      {
        path: "setting-point",
        name: "settingPoint",
        component: () => import("../views/setting/point.vue"),
      },
      // 站点信息申报新增
      {
        path: "setting-point-edit",
        name: "settingPointEdit",
        component: () => import("../views/setting/point-edit.vue"),
      },
      // 其他补充资料
      {
        path: "setting-supplementary",
        name: "SettingSupplementary",
        component: () => import("../views/setting/supplementary.vue"),
      },
      // 其他补充资料新增&修改
      {
        path: "setting-supplementary-edit",
        name: "SettingSupplementaryEdit",
        component: () => import("../views/setting/supplementary-edit.vue"),
      },
    ],
  },
  {
    path: "/boss",
    name: "boss",
    component: () => import(/* webpackChunkName: "boss" */ "../views/Boss.vue"),
  },
  {
    path: "/bind",
    name: "bind",
    component: () =>
      import(/* webpackChunkName: "bind" */ "../views/bind/bind.vue"),
  },
  {
    path: "/process",
    name: "process",
    component: () =>
      import(/* webpackChunkName: "process" */ "../views/process/process.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (token) {
    if (to.path == "") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.path == "/bind") {
      next();
    } else {
      next("/bind");
    }
  }
});

export default router;
