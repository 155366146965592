export default {
  methods: {
    // 添加缓存
    addKeepAlive(name) {
      const temp = this.$store.state.keepAliveList;
      if (temp.indexOf(name) == -1) {
        temp.push(name);
      }
      this.$store.commit("getKeepAliveList", temp);
    },
    // 移除缓存
    removeKeepAlive(name) {
      const temp = this.$store.state.keepAliveList;
      if (temp.indexOf(name) != -1) {
        temp.splice(temp.indexOf(name), 1);
        this.$store.commit("getKeepAliveList", temp);
      }
    },
    // 清除缓存
    clearKeepAlive() {
      this.$store.commit("getKeepAliveList", []);
    },
  },
};
