import base from "../base";
import axios from "../axios";

const index = {
  // 获取当日运维统计
  // getOpsStatistics() {
  //   return axios.get(base.api + "/wxIndex/operationalStatistics");
  // },
  // 获取当日运维统计
  getOpsStatistics(data) {
    return axios.get(base.api + "/wxIndex/operationalStatistics", {
      params: data,
    });
  },
  // 获取未完成事件统计
  getEventStatistics() {
    return axios.get(base.api + "/wxIndex/eventStatistics");
  },
  // 获取企业统计
  getEnterStatistics() {
    return axios.get(base.api + "/wxIndex/enterpriseStatistic");
  },
};

export default index;
