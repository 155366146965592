import base from "../base";
import axios from "../axios";

const table = {
  // 运维打卡表格接口
  postAddArcTabulation(data) {
    return axios.post(base.api + "/arcTabulation/addArcTabulation", data);
  },
};

export default table;
