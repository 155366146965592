/**
 * api接口的统一出口
 */
// base
import base from "./base";
// 公用
import common from "./common";
// 事件
import event from "./event/event";
// 首页
import index from "./index/index";
// 企业
import enterprise from "./enterprise/enterprise";
// 运维
import operation from "./operation/operation";
// 表格
import table from "./table/table";
// 领导事件舱
import boss from "./boss/boss";
// 电子档案
import setting from "./setting/setting";
// 导出接口
export default {
  common,
  event,
  index,
  enterprise,
  operation,
  boss,
  table,
  setting,
  base,
};
