/**
 * http配置
 */
import axios from "axios";
import { Toast } from "vant";
import router from "../router";
// import base from "./base";

const instance = axios.create({
  timeout: 1000 * 30,
});

instance.defaults.headers.post["Content-Type"] = "application/json";
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    // let token = "8888";
    config.headers["token"] = token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.state) {
        if (response.data.state != 0) {
          Toast.fail(response.data.msg);

          if (response.data.state === 4) {
            router.push("/bind");
            sessionStorage.clear();
            setTimeout(() => {
              location.reload();
            }, 500);
          }

          return Promise.reject(response);
        }
        return Promise.resolve(response);
      }
      return Promise.resolve(response);
    }
    Toast.fail("服务器错误");
    return Promise.reject(response);
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 500) {
        Toast.fail("网络异常");
        return Promise.reject(error);
      }
      if (error.status === 404 || error.response.status === 403) {
        Toast.fail("请求失败");
      } else {
        Toast.fail("未知错误");
        return Promise.reject(error);
      }
    } else {
      if (error.status === 500) {
        Toast.fail("网络异常");
        return Promise.reject(error);
      }
      if (error.status === 404 || error.status === 403) {
        Toast.fail("请求失败");
      } else {
        Toast.fail("未知错误");
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
