import base from "../base";
import axios from "../axios";

const boss = {
  // 领导驾驶舱事件统计
  getEventStatistics(data) {
    return axios.get(base.api + "/index/eventStatistics", {
      params: data,
    });
  },
  // 获取远程巡查统计
  getInspectStatistical() {
    return axios.get(base.api + "/index/inspectStatistical");
  },
  // 获取远程巡查单月数据
  getEnterpriseInspectStatistical(data) {
    return axios.get(base.api + "/index/enterpriseInspectStatistical", {
      params: data,
    });
  },
  // 事件推送
  getPushStatistics(data) {
    return axios.get(base.api + "/index/pushTheStatistics", { params: data });
  },
  // 获取企业评分排行
  getEnterpriseRank(data) {
    return axios.get(base.api + "/index/queryAllByType", {
      params: data,
    });
  },
};

export default boss;
