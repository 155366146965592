/*
 * @Descripttion:
 * @Author: yechonglin
 * @Date: 2024-04-23 15:54:40
 * @LastEditors: yechonglin
 * @LastEditTime: 2024-04-23 16:08:56
 * @FilePath: \bocon-am-dalian-wx\src\api\base.js
 */
/**
 * 接口域名的管理
 */
const base = {
  api: "",
  file: "",
};
if (process.env.NODE_ENV == "development") {
  base.api = "http://192.168.10.111:31184/api"; // 测试环境
  // base.api = "http://192.168.5.195:9102/api"; //传博
  // base.api = "http://192.168.5.13:9102/api"; //思达
  // base.api = "http://192.168.5.19:9102/api"; // 晓科
  // base.api = "http://192.168.5.29:9102/api"; //子旺
  // base.file = "http://192.168.10.101:20013";
  // base.api = "http://218.25.176.96:7102/api"; // 大连生产环境
  // base.file = "http://218.25.176.96:9090/dalian";
  base.file = "http://192.168.10.115:9097/newdalian";
  // base.file = "http://218.25.176.96:9090/dalian"; // 外网
} else {
  base.api = "http://120.201.246.91:3112/api";
  base.file = "http://172.24.33.16:9097/dalian";
}

export default base;
