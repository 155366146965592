import base from "../base";
import axios from "../axios";

const setting = {
  // 获取企业编辑和详情
  getEnterpriseInfo(data) {
    return axios.get(base.api + "/sys/enterprise/" + data.id);
  },
  // 获取行业列表
  getIndustryListAll() {
    return axios.get(base.api + "/arc/industry/queryAll");
  },
  putEnterpriseInfo(data) {
    // 修改企业基本信息
    return axios.put(base.api + "/arc/basicInfo/", data);
  },
  // 排污许可分页查询
  getPermit(data) {
    return axios.get(base.api + "/arc/permit/", {
      params: data,
    });
  },
  // 根据id查询排污许可证详情
  getPermitInfo(data) {
    return axios.get(base.api + "/arc/permit/" + data.id);
  },
  // 新增排污许可证信息
  postAddPermit(data) {
    return axios.post(base.api + "/arc/permit/", data);
  },
  // 根据id修改排污许可证信息
  putPermit(data) {
    return axios.put(base.api + "/arc/permit/", data);
  },
  // 获取应急预案列表
  getEmergencyList(data) {
    return axios.get(base.api + "/arc/measure/" + data.id, {
      params: data,
    });
  },
  // 应急预案通过id查询详情
  getArcMeetMeasureById(data) {
    return axios.get(
      base.api + "/arc/measure/getArcMeetMeasureById/" + data.id
    );
  },
  // 附件上传
  uploadFile(data) {
    return axios.post(base.api + "/oss/minio/upload/recover", data);
  },
  // 应急措施修改
  postEmergencyEditList(data) {
    return axios.post(base.api + "/arc/measure/update", data);
  },
  // 应急措施新增
  postEmergencyAddList(data) {
    return axios.post(base.api + "/arc/measure/", data);
  },
  // 查询环评信息列表
  getEiainfoList(data) {
    return axios.get(`${base.api}/sysEiaInformation/`, {
      params: data,
    });
  },
  // 根据id查询环评信息
  getEiaInfo(data) {
    return axios.get(`${base.api}/sysEiaInformation/getSysEiaInformationById`, {
      params: data,
    });
  },
  // 新增环评信息
  postAddEiaInfo(data) {
    return axios.post(
      `${base.api}/sysEiaInformation/addSysEiaInformation`,
      data
    );
  },
  // 修改环评信息
  postEditEiaInfo(data) {
    return axios.post(
      `${base.api}/sysEiaInformation/editSysEiaInformation`,
      data
    );
  },
  // 查询监测验收列表
  getMonitorAcceptanceList(data) {
    return axios.get(`${base.api}/sysMonitorAcceptance/`, {
      params: data,
    });
  },
  // 根据id查询监测验收
  getSysMonitorAcceptanceById(data) {
    return axios.get(
      `${base.api}/sysMonitorAcceptance/getSysMonitorAcceptanceById`,
      {
        params: data,
      }
    );
  },
  // 修改监测验收
  postEditSysMonitorAcceptance(data) {
    return axios.post(
      `${base.api}/sysMonitorAcceptance/editSysMonitorAcceptance`,
      data
    );
  },
  // 新增监测验收
  addSysMonitorAcceptance(data) {
    return axios.post(
      `${base.api}/sysMonitorAcceptance/addSysMonitorAcceptance`,
      data
    );
  },
  // 查询其他补充材料列表
  getOtherSupplementaryMaterialsList(data) {
    return axios.get(`${base.api}/sysOtherSupplementaryMaterials/`, {
      params: data,
    });
  },
  // 根据id查询环评信息
  getSysOtherSupplementaryMaterialsById(data) {
    return axios.get(
      `${base.api}/sysOtherSupplementaryMaterials/getSysOtherSupplementaryMaterialsById`,
      {
        params: data,
      }
    );
  },
  // 修改其他补充材料
  posteditSysOtherSupplementaryMaterials(data) {
    return axios.post(
      `${base.api}/sysOtherSupplementaryMaterials/editSysOtherSupplementaryMaterials`,
      data
    );
  },
  // 新增其他补充材料
  addSysOtherSupplementaryMaterials(data) {
    return axios.post(
      `${base.api}/sysOtherSupplementaryMaterials/addSysOtherSupplementaryMaterials`,
      data
    );
  },
  // 查询运维企业、一企一档、站点信息申报列表
  getInformationReviewListPage(data) {
    return axios.get(
      `${base.api}/informationReview/getInformationReviewListPage`,
      {
        params: data,
      }
    );
  },
  // 根据id查询信息审核
  getInformationReviewById(data) {
    return axios.get(`${base.api}/informationReview/getInformationReviewById`, {
      params: data,
    });
  },
  // 识别关联id、type下是否有存储未审批资源
  getInformationRecognition(data) {
    return axios.get(`${base.api}/informationReview/recognition`, {
      params: data,
    });
  },
  // 提交信息审核
  postAddInformationReview(data) {
    return axios.post(
      `${base.api}/informationReview/addInformationReview`,
      data
    );
  },
};

export default setting;
