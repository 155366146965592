export default {
  directives: {
    padding: {
      bind(el, binding) {
        if (binding.arg == "top") {
          el.style.paddingTop = binding.value + "px";
        }
        if (binding.arg == "bottom") {
          el.style.paddingBottom = binding.value + "px";
        }
        if (binding.arg == "left") {
          el.style.paddingLeft = binding.value + "px";
        }
        if (binding.arg == "right") {
          el.style.paddingRigth = binding.value + "px";
        }
      },
    },
  },
};
