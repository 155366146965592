import { Dialog, Toast } from "vant";
import wx from "weixin-js-sdk";

export default {
  methods: {
    // 后退
    goBack() {
      this.$router.go(-1);
    },
    // 切换账号
    changeUser() {
      Dialog.confirm({
        title: "是否切换用户？",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push({
            name: "bind",
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getSdkConfig(jsApiList) {
      const data = {
        url: window.location.href.split("#")[0],
        jsapiTicket: "",
      };
      this.$api.common.getSdkConfig(data).then((res) => {
        wx.config({
          debug: false, // 开启调试模式,
          appId: "wxa19cd448cf77a531", // 必填，企业号的唯一标识，此处填写企业号corpid
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录1
          jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.error(function (err) {
          Toast.fail("出错了：配置错误"); // 这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
          console.log(err);
        });
        wx.ready(function () {
          wx.checkJsApi({
            jsApiList,
            success(ready) {
              console.info(ready);
            },
          });
        });
      });
    },
  },
};
