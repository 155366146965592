import qs from "qs";
import base from "../base";
import axios from "../axios";

const event = {
  // 通过站点id查询title数据
  getHeadDataByPonitid(data) {
    return axios.get(base.api + "/monitor/data/head/" + data.pointId, {
      params: data,
    });
  },
  // 获取站点数据
  getDataByPoint(data) {
    return axios.get(base.api + "/monitor/data/dalianList/chart", {
      params: data,
    });
  },
  // 获取事件统计
  getEventCount(data) {
    return axios.get(base.api + "/enforcement/wxNotCompletedEventList", {
      params: data,
    });
  },
  // 根据事件id查询证据链
  getEvidenceByEventId(data) {
    return axios.get(base.api + "/enforcement/getEvidenceByEventId", {
      params: data,
    });
  },
  // //处理事件
  // processEnterprise(data) {
  //   return axios({
  //     url: base.api + "/enforcement/enterpriseReply",
  //     method: "post",
  //     data: qs.stringify(data),
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   });
  // },
  processEnterprise(data) {
    return axios.post(base.api + "/enforcement/enterpriseReply", data);
  },
  // 企业回复获取所有原因
  getSysReason(data) {
    return axios.get(base.api + "/sysReason/query", {
      params: data,
    });
  },
  // 超标事件证据链图表查询
  getEventExcessiveList(data) {
    return axios.get(base.api + "/monitor/data/dalianList/chart", {
      params: data,
    });
  },
  // 获取事件列表
  getEventList(data) {
    return axios.get(base.api + "/enforcement/wxQueryCompletedEventPage", {
      params: data,
    });
  },
  // 获取所有行业
  getIndustryListAll() {
    return axios.get(base.api + "/arc/industry/queryAll");
  },
  // 远程巡查-事件报告
  getInspectReport(data) {
    return axios.get(base.api + "/enforcement/inspectReport2", {
      params: data,
    });
  },
  // 事件报告
  getReport(data) {
    return axios.get(base.api + "/enforcement/getEventReportByEventId2", {
      params: data,
    });
  },
  postMinioFilesByResourcesId(data) {
    return axios.get(base.api + "/sys/file/minio/minioFilesByResourcesId", {
      params: data,
    });
  },
  // 普通事件-执法部门回复
  postEpaReply(data) {
    // return axios({
    //   url: base.api + "/enforcement/epaReply",
    //   method: "post",
    //   data: qs.stringify(data),
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   }
    // });
    return axios.post(base.api + "/enforcement/epaReply", data);
  },
  // 远程巡查事件-执法部门回复
  postInspectEpaReply(data) {
    return axios({
      url: base.api + "/enforcement/inspectEpaReply",
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
};

export default event;
