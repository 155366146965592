import Vue from "vue";
// moment.js
import moment from "moment";
// 加密
import md5 from "js-md5";
// api
// vchart
import VePie from "v-charts/lib/pie.common";
import VeLine from "v-charts/lib/line.common";
import VeHistogram from "v-charts/lib/histogram.common";
import vueEsign from "vue-esign";
import api from "@/api/index";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// 按需引入vant
import "./assets/lib/vant";

// 自定义样式
import "./assets/style/home/home.less";
import "./assets/style/home/boss.less";
import "./assets/style/index/index.less";
import "./assets/style/event/event.less";
import "./assets/style/enterprise/enterprise.less";
import "./assets/style/operation/operation.less";
import "./assets/style/common.less";
import "./assets/style/bind/bind.less";

// 自定义js文件
import common from "./assets/lib/common";
import directives from "./assets/lib/directives";
import keepalive from "./assets/lib/keepalive.js"; // 公用缓存

// iconfont
import "./assets/iconfont/iconfont.css";

// rem适配
import "./assets/lib/rem";

Vue.use(vueEsign);

Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;

Vue.config.productionTip = false;

Vue.component(VePie.name, VePie);
Vue.component(VeLine.name, VeLine);
Vue.component(VeHistogram.name, VeHistogram);

// 混入
Vue.mixin(common);
Vue.mixin(directives);
Vue.mixin(keepalive);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
