import base from "../base";
import axios from "../axios";

const operation = {
  // 获取运维站点
  getOperationSite(data) {
    return axios.get(base.api + "/wxOpsModule/queryPointInfoAll", {
      params: data,
    });
  },
  // 获取运维记录数据
  getOperationData(data) {
    return axios.get(
      base.api + "/arcOperationalRecords/queryByEnterpriseIdPage",
      {
        params: data,
      }
    );
  },
  // 运维表格
  // 运维表格-查询sn号是否存在
  getPointMtn(data) {
    return axios.get(base.api + "/tabulation/getPointMtn", {
      params: data,
    });
  },
  // 运维表格-开始运维
  getStartOperation(data) {
    return axios.get(base.api + "/tabulation/start", {
      params: data,
    });
  },
  // 运维表格-结束运维
  getEndOperation(data) {
    return axios.get(base.api + "/tabulation/end", {
      params: data,
    });
  },
};

export default operation;
