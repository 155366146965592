import base from "../base";
import axios from "../axios";

const enterprise = {
  // 查询仪表状态
  getStateData(data) {
    return axios.get(
      base.api + "/dmDeviceStatusRecord/getHistoryDataByPoitnId",
      {
        params: data,
      }
    );
  },
  // 查询仪表参数
  getParameterData(data) {
    return axios.get(
      base.api + "/dmDeviceParamRecord/getHistoryDataByPoitnId",
      {
        params: data,
      }
    );
  },
  // 根据站点id查询超标数据
  getOverproofData(data) {
    return axios.get(
      base.api + "/arcStandardData/getArcStandardDataByPointId",
      {
        params: data,
      }
    );
  },
  // 根据站点查询因子
  getFactorData(data) {
    return axios.get(base.api + "/arcStandardData/getDivisorNameByPointId", {
      params: data,
    });
  },
  // 根据站点查询监测数据
  getDataData(data) {
    return axios.get(base.api + "/monitor/data/fujianList", {
      params: data,
    });
  },
  // 根据站点id查询监测数据title
  getDataTitleByPointId(data) {
    return axios.get(base.api + "/monitor/data/head/" + data.id, {
      params: data,
    });
  },
  // 获取站点列表
  getPointList(data) {
    return axios.get(base.api + "/wxEnterpriseModule/query", {
      params: data,
    });
  },
  // 查询所有站点
  getAllPointList(data) {
    return axios.get(base.api + "/apply/query", {
      params: data,
    });
  },
  // 停运申请
  getOutageApplication(data) {
    return axios.post(base.api + "/apply/", data);
  },
  // 停运申请
  getOffStreamHistory(data) {
    return axios.get(base.api + "/apply/record", {
      params: data,
    });
  },
  // 根据id查询审批详情
  getApplyInfo(data) {
    return axios.get(base.api + "/apply/" + data.id);
  }, // 审批停运管理
  getApplyApproval(data) {
    return axios.get(base.api + "/apply/approval", { params: data });
  },
  // 停运申请恢复
  getRecoverApplication(data) {
    return axios.post(base.api + "/recover/", data);
  },

  // 获取可申请停运恢复记录 分页查询
  getRecoverData(data) {
    return axios.get(base.api + "/recover/data", { params: data });
  },
  // 获取停运恢复申请记录 分页查询
  getRecoverRecord(data) {
    return axios.get(base.api + "/recover/record", { params: data });
  },
  // 根据id获取申请流程详情
  getBizApply(data) {
    return axios.get(base.api + "/recover/getBizApply", { params: data });
  },
  getApproval(data) {
    return axios.get(base.api + "/recover/approval", { params: data });
  },
};

export default enterprise;
